@import '../../../styles/variables';

.white-border {
    border-left: 2px solid white;
}

.status-div {
    margin: 0 16px;
    padding: 6px;
    border-radius: 50px;
    width: 80%;
}

.status-default {
    background: $brand-secondary;
}

.status-progress {
    background: $primary-warning;
}

.status-complete {
    background: $secondary-complete;
}

.status-invoiced {
    background: $secondary-success;
}

@media screen and (max-width:1024px){
    .status-div{
        font-size: 75%;
    }
}
@media screen and (max-width:$tab-device) {
    .status-div {
        font-size: 100%;
    }
}