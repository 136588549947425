@import '../../../styles/variables';

table {
    tbody {
        .expanded-column {
            background-color: $active-task !important;
        }
    }
}

.pagination-container {
    // top: 5px;
    // width: 30px;
    // .prev {
    //     width: 100%;
    //     height: auto;
    //     display: block;
    //     fill: $primary-success;
    // }
    // .next {
    //     width: 100%;
    //     height: auto;
    //     display: block;
    //     fill: $primary-success;
    // }
    // .prev-disabled, .next-disabled {
    //     fill: $heading-text;
    // }

    .svg-container {
        width: 23px;
        height: auto;
        display: block;
        margin-left: 16px;
        .next, .prev {
            .svg-arrow {
                fill: $primary-success;
            }
        }
        .prev-disabled, .next-disabled {
            .svg-arrow {
                fill: $heading-text;
            }
        }
    }

}