@import '../../styles/variables';


.formMainContainer {
  .formTitleContainer {
    .formTitle {
      color: $body-text;
    }
  }

  .formContainer {
    padding-bottom: unit(16px);

    .inputContainer {
      margin-bottom: 16px;
      position: relative;

      .inputLoader  {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
        right: 16px;
      }

      .inputLabel {
        text-transform: uppercase;
        color: $body-text;
        margin-bottom: 8px;
      }

      .inputField {
        color: $body-text;
        background-color: $brand-primary;
        box-shadow: none;
        outline: 0;
        width: 100%;
        padding: unit(8px) unit(16px);
        border-radius: 5px;
        border: 1px solid transparent;

        &:focus {
          outline: 0;
          box-shadow: 0;
          background: white;
          border-color: $brand-primary;
        }
      }

      .textField {
        min-height: 200px;
      }

      .sliderContainer {
        >div {
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }

    .termMainContainer {
      background: $brand-primary;
      color: $body-text;
      border-radius: 16px;
      padding: unit(16px);

      .termTitleContainer {
        margin-bottom: 16px;
      }

      .termHeaderContainer {
        margin-bottom: 16px;
        display: flex;
        flex-wrap: wrap;

        input {
          display: block;
          flex: 0 0 32px;
          margin-right: 32px;
        }

        .headerText {
          width: calc(100% - 64px);
        }
      }

      .termContainer {
        height: 400px;
        overflow-y: auto;

        >p {

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }

    .btnContainer {
      margin-top: 32px;

      button {
        background: $primary-success;
        color: white;
        font-size: unit(18px);
        font-weight: 500;
        border-radius: 30px;
        width: 100%;
        padding: unit(8px) 0;
      }
    }

  }
}

@media (min-width:1024px) {
  .formMainContainer {

    .formTitleContainer {
      margin-bottom: 32px;
    }

    .formContainer {
      width: 70%;


      .selectContainer {
        width: 48%;
      }


      .sliderContainer {
        display: flex;

        >div {
          margin-right: 16px;

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }

      .flexContainer {
        display: flex;
        justify-content: space-between;

        >div {
          flex: 0 0 48%;
          max-width: 48%;
        }
      }
    }


    .btnContainer {
      width: 40%;
    }
  }
}