.input-label {
    margin: 0 16px 0 0;
}
.employee-input {
    margin-bottom: 0;
}
.add-products .input-label{
    margin:0;
  }

  .error-message    {
      padding-left: 170px;
      margin-top: 8px;
  }