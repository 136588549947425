@import '../../styles/variables';

.task-main-container  {
  .headerContainer {
    margin-bottom: unit(32px);

    .taskHeading  {
      margin-bottom: 0;
    }

    .refreshBtnContainer {
      margin-left: 16px;
    }
  }
}