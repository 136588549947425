// background color
$brand-primary:#F6F6F6;
$brand-secondary:#C5C5C5;
$light-color:white;

// text color
$heading-text:#7E7E7E;
$body-text:#707070;
$user-name-color:#373737;
$error-color:#D43B3B;
$lightest-color:#efefef;

// button color
$primary-success:#71BF5D;
$secondary-success:#C1E6B7;
$primary-warning:#FDFFC4;
$secondary-warning:#D5CE0A;
$secondary-complete:#f7c679;

// active tasks
$active-task:#ecf5ea;

// width 
$tab-device: 768px; 


@function unit($pixels, $context: 16px) {
  @return ($pixels / $context) * 1rem;
}
