@import '../../../styles/variables';

.input-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
}

@media screen and (max-width:$tab-device){
    .input-container {
        position: inherit;
        top: 0;
        right: 0;
        width: 250px;
    }   
}