/** @format */

@import '../../../styles/variables';

.task-list {
  color: inherit;
  border-collapse: separate;
  border-spacing: 0 1em;

  .list-header {
    overflow: hidden;
    border: 2px solid $brand-primary;
    border-radius: 14px;

    // height: 50px;
    .header-column {
      padding: unit(16px) 0;
      vertical-align: middle;
      border: 0;
      font: inherit;
      font-size: unit(14px);
      border-top: 2px solid $brand-primary;
      border-bottom: 2px solid $brand-primary;

      &:first-child {
        border-left: 2px solid $brand-primary;
      }

      &:last-child {
        border-right: 2px solid $brand-primary;
      }

      .dark-border {
        border-left: 2px solid $brand-primary;
      }
    }

    .Tasks {
      width: 40%;

      .dark-border {
        border-left: 0;
      }
    }

    .Arrived,
    .Completed,
    .Worker,
    .Status {
      width: 15%;
      text-align: center;
      // border-left: 2px solid $brand-primary;
    }
  }

  .list-content {
    .content-td {
      border: 0;
      padding: 0;
      vertical-align: middle;
      text-align: center;
      padding: unit(16px) 0;
      font-size: unit(14px);
      background: $brand-primary;

      .white-border {
        .loader-container {
          margin-left: 16px;
          height: 30px;
        }
      }

      .required-column {
        h3 {
          color: $primary-success;
        }

        .require-text {
          color: $light-color;
          background: $primary-success;
          border-radius: 10px;
          font-size: unit(10px);
          padding: 3px 8px;
        }
      }

      .dropdown-btn-container {
        width: 80% !important;
        margin: 0 auto;

        #dropdown-basic {
          background: white;
          border: 0;
          width: 100%;
          border-radius: 50px;
          outline: 0;
          box-shadow: none;
          font: inherit;
          color: $body-text;
          text-align: left;
          height: 33px;
          position: relative;
        }
      }

      .dropdown-menu {
        border: 0;

        .dropdown-item {
          color: inherit;

          @media screen and (max-width: $tab-device) {
            font-size: 0.875rem;
          }

          &:hover,
          &.active {
            background: $primary-success;
          }
        }
      }

      .dropdown-toggle {
        height: 100%;

        &::after {
          position: absolute;
          content: '';
          top: 45%;
          right: 10px;
          border-top: 0.3em solid $heading-text;
        }
      }
    }
  }

  .collapse-content {
    .collapse-row {
      border: 0;
      padding: 0;
    }
  }
}

@media screen and (max-width:1024px) {
  .task-list {
    .list-header {
      .header-column {
        padding: unit(16px) unit(15px);
      }
    }

    .list-content {

      .content-td {

        .dropdown-btn-container {
          #dropdown-basic {
            font-size: 9.5px;
          }
        }
      }

    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 768px) {
  .custom-row {
    .custom-status {
      .white-border {
        .status-div {
          width: 70%;
        }
      }
    }
  }
}

@media screen and (max-width:$tab-device) {

  .task-list {
    .list-header {
      display: block;
      overflow: hidden;
      border: 0;
      border-radius: 0;
      margin-bottom: 20px;

      .header-column {
        padding: 0;
        float: left;
        width: auto;
        text-align: center;
        margin: 1%;
        border: 0;

        .dark-border {
          padding: unit(8px);
          border: 1px solid;
          font-size: unit(12px);

        }

        &:first-child {
          display: none;
        }

        &:last-child {
          border-right: 0;
          display: none;
        }

      }

    }

    .list-content {

      .content-td {
        font-size: 0.7rem;

        h3 {
          font-size: 0.8rem;
        }


      }

    }

  }
}

.collapse-row {
  border-radius: 25px;
  overflow: hidden;
  padding: 0.75rem 0 !important;

  .admin-details-container {
    border: none;
  }
}

.custom-row {

  .custom-task-width {
    // flex: 0 0 40%;
    // max-width: 40%;

    .custom-toggle-icon {
      margin: 0 16px;
      // transform: rotateZ(-180deg);
    }
  }

  .pl-16 {
    padding-left: 16px;
  }

  .custom-task-w-15 {
    // flex: 0 0 15%;
    // max-width: 15%;
    text-align: center;

    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show>.btn-primary.dropdown-toggle {
      background-color: white !important;
      background: white;
      border: 0;
      width: 100%;
      border-radius: 50px;
      outline: 0;
      box-shadow: none;
      font: inherit;
      color: $body-text;
      text-align: left;
      height: 33px;
      position: relative;
    }

    .btn-primary {
      background: white;
      border: none;
      position: relative;
      border-radius: 50px;

      &:focus {
        background-color: white;
        outline: none;
        box-shadow: none;
      }

      &::after {
        position: absolute;
        top: 50%;
        right: 10%;
        color: black;
        z-index: 999;
        transform: translateY(-50%)
      }


      .input-field {
        margin-bottom: 0;

        &:hover {
          cursor: pointer;
        }
      }

    }

    .dropdown {
      .dropdown-btn-container {
        width: 80%;

        @media screen and (max-width: $tab-device) {
          width: 80%;
        }

        margin: 0 auto;
      }

      .dropdown-item {
        font-size: 0.875rem;

        &:hover {
          background-color: $primary-success;
        }
      }
    }
  }
}

@media screen and (max-width:$tab-device) {
  .task-list-container {
    overflow-x: scroll;
  }

  table.task-list.table,
  table.task-list.table thead,
  table.task-list.table .list-header {
    display: block;
  }

  .task-list {

    tbody {
      display: block;

      .collapse-row {
        border-top: none;

        .custom-row {
          display: flex;
          flex-wrap: wrap;
          padding: 10px;
          width: 100%;
          margin-bottom: unit(20px);

          h3 {
            font-size: 1rem;
          }

          h6 {
            font-size: 0.75rem;
            margin-bottom: 5px;
          }

          .custom-task-width {
            width: 100% !important;
            max-width: 100% !important;
            padding: 0;
          }

          .custom-arrived {
            text-align: center;
            border-left: none;
            position: relative;
            left: 0;
            padding: 0.75rem;
          }

          .custom-completed {
            text-align: center;
            border-left: none;
            padding: 0.75rem;
          }

          .custom-worker {
            text-align: center;
            border-left: none;
            padding: 0.75rem;
          }

          .custom-status {
            text-align: center;
            padding: 0.75rem;

            .white-border {
              border-left: none;

              .status-div {
                width: 75% !important;
                margin: 0 auto;
              }
            }

          }
        }
      }

    }

  }

}

@media screen and (max-width: 767px) {
  .task-list .list-content .content-td .dropdown-btn-container #dropdown-basic {
    width: 150px !important;
  }
}

@media screen and (max-width: 425px) {
  .task-list .list-content .content-td .dropdown-btn-container #dropdown-basic {
    width: 100px !important;
  }
}