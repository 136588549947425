@import '../../styles/variables';

.summary-container {
  position: relative;
  h1 {
    margin-bottom: 32px;
  }
}

.new-task-container {
  margin-top: unit(64px);
}

@media screen and (max-width:$tab-device){
    .new-task-container {
      margin-top: 2rem;
      float: left;
      width: 100%;
  }
}

