/** @format */

@import '../styles/variables';

.sidebar {
  height: 100%;
  background: $brand-primary;
  position: relative;
  overflow: hidden;
  z-index: 999;
  border-radius: 40px;
  padding: unit(16px);

  .profile-container {
    background: white;
    padding: unit(8px);
    border-radius: 33px;

    .profile-img-container {
      width: unit(35px);
      height: unit(35px);
      border-radius: 100%;
      background: gray;
    }

    .user-name {
      margin: 0 0 0 unit(16px);
      color: $user-name-color;
    }

    .logout-link {
      display: block;

      .logout-icon {
        display: block;

        .icon {
          fill: $brand-secondary;
        }
      }
    }
  }

  .sidebar-link-container {
    margin-top: unit(32px);
    padding: 0 unit(32px);

    .lang-dropdown{
    
      .sidebar-link {
        margin:0;
      }
    }

    > div {
      margin-bottom: 16px;
    }
    .sidebar-link {
      display: block;
      text-decoration: none;
      color: $heading-text;
      flex: 1;
      // margin-bottom: unit(16px);

      .sidebar-icon {
        display: block;
        margin-right: unit(16px);

        .icon {
          fill: $heading-text;
        }
      }

      &:hover,
      &.active {
        color: $primary-success;

        .sidebar-icon {
          .icon {
            fill: $primary-success;
          }
        }
      }
    }

    .refreshBtn {
      svg {
        fill: $heading-text;
      }
      &:hover {
        svg {
          fill: $primary-success;
        }
      }
    }
  }

  .bg-img {
    position: absolute;
    z-index: -1;
  }

  .left-img {
    top: 178px;
    left: 0;
  }
  .right-img {
    bottom: 0;
    right: 0;
  }
  .name-img {
    bottom: 32px;
    width: 100%;
    left: 0;
    text-align: center;
    color: $brand-secondary;
    font-size: unit(18px);
    font-weight: 500;
  }
}
.lang-dropdown {
  // margin-bottom: 16px;
  position: relative;
  top: 0;
  left: -12px;
  .dropdown-btn-container {
    button, .dropdown-toggle {
      background-color: transparent;
      border: 0;
      outline: 0;
      box-shadow: none;
      color: $heading-text;
      &::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid $heading-text;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
      }
    }
  }
  .langauge-dropdown {
    display: block;
    top: 100%!important;
    right: 0!important;
    transform: translate3d(0px, 0px, 0px)!important;
  }
  button#dropdown-basic:active {
    background: transparent;
    color: $body-text;
    box-shadow: none;
    outline: 0;
  }
}

@media screen and (max-width:1024px){

  .sidebar {

    border-radius:0;
    width:unit(250px);
    z-index: 9999;
    height: calc(100vh - 80px);
    box-shadow: unit(4px) unit(0px) unit(13px) unit(0px) $lightest-color;

    div{

      .profile-container{
        display: none  !important;
      }

      .sidebar-link-container{
        padding:0 1rem;

        .lang-dropdown{
          position: relative;
       

          .sidebar-link{
            margin-bottom:0;
          }
        }

        
      }
    }

    .name-img{
      font-size:unit(13px);
    }
  }

  .mobile-nav 
      {
        .profile-container {
          position: absolute;
          right: 1rem;

           img{
             width: unit(30px);
             height: unit(30px);
             border-radius: 50%;
             padding: 0;
           }
           .user-name{
             padding:0 unit(10px);
           }
           .logout-link{
             img{
              width: unit(20px);
              height: unit(20px);
             }
           }
        }
      }
  
}

@media screen and (max-width:$tab-device) {
  .mobile-nav 
      {
        .profile-container {
          position: absolute;
          right: 1rem;

          img.profile-img-container,  .user-name
          {
             display: none;
           }
           .logout-link{
             img{
              width: unit(20px);
              height: unit(20px);
             }
           }
        }
      }
      .sidebar {

          .sidebar-link-container{
          
              .sidebar-link{
                .sidebar-icon {
                 
                  width: 18px;
              }
              
            }
    
            
          }
          .left-img {
            top: 0;
           
         }
        
    
        
      }
}