@import '../../styles/variables'; 

.notification-container {

  .notification-table {
    border-collapse: separate;
    border-spacing: 0 1em;

    tr.notification {
        background: transparent;
        td {
            border-top: 0;
        }
    }

    .list-header {
      overflow: hidden;
      border: 2px solid #f6f6f6;
      border-radius: 14px;

      .header-column {
        border-top: 2px solid #f6f6f6;
        border-bottom: 2px solid #f6f6f6;
        padding: 1rem 0;
        vertical-align: middle;
        font: inherit;
        font-size: .875rem;

        &:first-child {
          border-left: 2px solid #f6f6f6;
        }

        &:last-child {
          border-right: 2px solid #f6f6f6;
        }
      }
    }
  }

  .mark-btn {
    color: $primary-success;
    font-size: unit(14px);
  }

  .Tasks {
    width: 70%;
  }
  .Arrived {
    text-align: center;
  }

  .notification {
    background: $brand-primary;
    padding: unit(16px);
    border-radius: 14px;
    margin-top: unit(16px);

    .content-td {
      border: 0px;
      .white-border {
        text-align: center;
      }
    }

    .notification-icon  {
      position: relative;

      &::after  {
        position: absolute;
        content: '';
        right: -2px;
        width: 12px;
        height: 12px;
        background: $primary-success;
        border-radius: 100%;
      }
    }

    .message {
      margin-left: unit(16px);
    }

    > div {
      &:nth-child(1) {
        width: 70%;
      }
      &:nth-child(2) {
        width: 22%;
      }
    }
    .loader-container {
      width: 65px;
      margin-left: 16px;
      height: 30px;
    }
  }
}

@media screen and (max-width:$tab-device){
  .notification-container .notification{
    flex-direction: column;
    align-items: flex-start !important;
    font-size: 0.8rem;
    padding:10px;
    
    > div {
      &:nth-child(1) {
        width: 100%;
        margin-bottom: 10px;
      }
      &:nth-child(2) {
        width: 100%;
      }
    }
    .notification-icon{
      img{
        height: 25px;
      }
    }
  }
}