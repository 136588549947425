.reset-password-page  {
  .login-container  {
    .input-label  {
      width: 230px;
      text-align: left;
    }

    .input-field  {
      margin-bottom: 0;
    }
  }
}