/** @format */
@import '../../styles/variables';

.login-main-container {
  height: 100vh;
  position: relative;
  background: url(../../svgs/login-back.svg) no-repeat $brand-primary;
  background-size: 100% auto;
  background-position: top left;
}

.bg-img {
  position: absolute;
}

.top-left {
  top: 0;
  left: 0;
}
.bottom-left {
  bottom: 0;
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
}

.left-oval {
  bottom: 250px;
  left: 200px;
}

.right-oval {
  top: 300px;
  right: 32px;
}

.logo {
  top: unit(80px);
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 300px;
}

.right-box {
  bottom: unit(32px);
  right: unit(32px);
  width: 25%;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.login-container {
  padding: unit(32px);
  background: $light-color;
  box-shadow: 0px 32px 74px #0000000d;
  border-radius: 17px;

  .input-field {
    border-radius: unit(120px);
    font-size: unit(18px);
    padding: unit(4px) unit(16px);
    color: $body-text;
  }

  h1 {
    margin-bottom: 32px;
    color: #373737;
  }

  .project-btn {
    margin-top: unit(64px);
  }
}
@media screen and (max-width: 1366px) {
  .login-main-container {
    background-size: cover;
  }
}
@media screen and (max-width: 1024px) {
  .logo {
    top: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .bg-img.right-box {
    width: 50%;
    bottom: 1rem;
  }
  .logo {
    transform: none;
    position: initial;
    margin-bottom: 40px;
  }
  .bg-img.right-box {
    position: initial;
    margin-top: 40px;
  }
  .login-main-container {
    flex-direction: column;
  }
  .login-container .project-btn {
    margin-top: 1rem;
  }
}
@media screen and (max-width: $tab-device) {
}
