/** @format */
@import '../../styles/variables';

.register-main-container {
  min-height: 100vh;
  position: relative;
  background: url(../../svgs/login-back.svg) no-repeat $brand-primary;
  background-size: 100% auto;
  background-position: top left;
  padding: 15px;

  .bg-img {
    position: absolute;
    z-index: -1;
  }

  .top-left {
    top: 0;
    left: 0;
  }
  .bottom-left {
    bottom: 0;
    left: 0;
  }

  .top-right {
    top: 0;
    right: 0;
  }

  .left-oval {
    bottom: 250px;
    left: 200px;
  }

  .right-oval {
    top: 300px;
    right: 32px;
  }

  .logo {
    top: unit(40px);
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
  }

  .right-box {
    bottom: unit(32px);
    right: unit(32px);
    width: 25%;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .register-container {
    padding: unit(32px) unit(48px);
    background: $light-color;
    box-shadow: 0px 32px 74px #0000000d;
    border-radius: 17px;
    max-width: 700px;
    width: 100%;

    h1 {
      margin-bottom: 32px;
      color: #373737;
    }

    .input-field {
      border-radius: unit(120px);
      font-size: unit(16px);
      padding: unit(4px) unit(16px);
      color: $body-text;
      margin-bottom: 10px;
      text-transform: capitalize;
    }

    .img-column {
      width: 100px;

      .uploaded-img-container {
        background: $brand-secondary;
        border-radius: 100%;
        width: 100%;
        height: 100px;
        overflow: hidden;
        .uploaded-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }

      /* start of file upload input as button */
      // .upload-btn-container {
      //   position: relative;
      //   overflow: hidden;
      //   display: inline-block;
      // }
      // .upload-btn-container .upload-btn {
      //   border: 1px solid white;
      //   color: #ffffff;
      //   background-color: #182c61;
      //   padding: 8px 20px;
      //   font-size: 16px;
      // }
      // .upload-btn-container input[type='file'] {
      //   font: inherit;
      //   font-size: 100px;
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   opacity: 0;
      // }
      // .upload-btn-container input[type='file']::-webkit-file-upload-button {
      //   cursor: pointer;
      // }

      /* end of file upload input as button */
    }

    .input-column {
      // width: 65%;k
      width: calc(100% - 120px);
    }

    // .input-field  {
    //   margin-bottom: 8px;
    // }

    .project-btn {
      margin-top: unit(16px);
    }
  }
}

@media screen and (max-width: 1366px) {
  .register-main-container {
    background-size: cover;
  }
}
@media screen and (max-width: 1280px) {
  .register-main-container {
    background-size: cover;
  }
}
@media screen and (max-width: $tab-device) {
  .register-main-container {
    .register-container {
      padding: 1rem;

      .input-field {
        padding: 0.45rem 1rem;
        float: left;
        margin-bottom: 10px;
      }

      .img-column {
        width: 80px;

        .uploaded-img-container {
          width: 100%;
          height: 80px;
        }
      }

      .input-column {
        width: calc(100% - 100px);
      }
    }
  }
}
