/** @format */

@import '../../../styles/variables';

.admin-details-container {
  border: 2px solid $brand-primary;
  padding: unit(16px) unit(16px) unit(64px);
  border-radius: 14px;
  border: 2px solid $primary-success;

  &.loader-padding-container {
    padding: unit(64px) unit(16px) unit(64px);
  }

  .details-content {
    margin-top: unit(32px);

    p {
      font-size: unit(12px);
    }

    h3 {
      font-size: unit(14px);
      margin-bottom: unit(8px);
      // word-break: break-all;
    }

    .column {
      width: 25%;

      .require-attention {
        background-color: $primary-success;
        color: white;
        padding: 16px;
        border-radius: 4px;
        box-shadow: 2px 2px 6px #00000054;
      }

    }
  }

  .driven-container {
    margin-top: unit(64px);
    width: 100%;

    .column {
      // width:48%;
      padding-right: 3%;

      .field-heading {
        margin-bottom: 8px;
      }

      h2 {
        font-size: unit(14px);

      }

      .driven-value {
        color: $primary-success;
        // margin-left: unit(16px);
        font-size: unit(14px);
        font-weight: 500;
      }


      &:nth-child(2),
      &:nth-child(4) {
        >div {
          &:nth-child(2) {
            .input-field {
              padding-right: 2px;
              margin-bottom: 0;
              font-size: unit(14px);
            }
          }

        }
      }

      >div:first-of-type {
        width: 70%;

        @media screen and (max-width: 768px) {
          margin-bottom: 8px;
        }
      }

      .loader-container {
        width: 65px;
        margin-left: 16px;
        height: 30px;
      }

    }
  }

  .product-container {
    margin-top: unit(64px);

    .product-content {

      // display: flex;


      .column-container {
        width: 100%;

        .column {
          margin-top: unit(16px);
          background: $brand-primary;
          padding: unit(8px);
          border-radius: 8px;

          .loader-container {
            width: 65px;
            margin-left: 16px;
            height: 30px;
          }

          p {
            font-size: unit(14px);
            margin-top: 8px;
          }

          .column-input {
            // width: 250px;
            width: 37%;

            .input-label {
              font-size: unit(14px);
              color: inherit;
              margin: 0;
            }

            .input-field {
              width: 30%;
              font-size: unit(14px);
              margin-bottom: 0;
              background: $light-color;
              text-align: center;
            }
          }

          &:first-child {
            margin-bottom: unit(16px);
          }
        }
      }
    }

    .total-container {
      // width: 35%;
      margin-top: 32px;
      align-items: flex-end;
      width: 100%;
      padding-left: 30px;

      >div {
        display: flex;
        align-items: center;
      }

      p {
        font-size: unit(18px);
      }

      .total-label {
        text-align: right;
        margin-right: 16px;

        .no-tax-label {
          font-size: unit(10px);
          color: $brand-secondary;
        }

        .total-value {
          margin-left: unit(8px);
          color: $primary-success;
          font-size: unit(35px);
          font-family: 'Bahnschrift';
        }

        .project-btn {
          margin-left: unit(20px);
          font-size: unit(19px);
          padding: unit(14px) unit(50px);
          height: unit(55px);

        }
      }
    }
  }
}

@media screen and (max-width:$tab-device) {
  .admin-details-container {

    float: left;
    padding-bottom: 0;

    .details-content,
    .driven-container {
      display: block !important;
      float: left;
      margin-top: 0rem;
    }



    .details-content .column,
    .driven-container .column {
      width: 100%;
      padding-right: 0;
      float: left;
      margin-top: 20px;
    }

    .driven-container .column {
      margin-top: 10px;

    }

    .product-container {
      margin-top: 2rem;
      float: left;
      width: 100%;

      .total-container {
        align-items: flex-end;
        width: 100%;
        justify-content: flex-start;
        padding: 0;

        >div {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .total-label {
          display: flex;

          .total-value {
            margin-left: 10px;
            margin-right: 10px;
            font-size: 1.8rem;
          }
        }

        p {
          text-align: left;
        }
      }

      .product-content {
        .column-container {
          .column {

            // display: block !important;

            &>div {
              width: 100% !important;
              margin-bottom: 7px;
            }

            .column-input {
              .input-label {
                width: 60%;
              }

              .input-field {
                width: 30%;
                padding: 0.5rem;
              }
            }
          }
        }
      }
    }
  }

  .row-column {
    .row-column-input {
      width: 100% !important;
    }
  }
}



@media screen and (max-width:1024px) {
  .admin-details-container {

    // .driven-container {
    //   > div {
    //     .column {
    //       > div:first-of-type {
    //         width: 70%;
    //       }
    //     }
    //   }
    // }

    .product-container {

      .product-content {
        display: block;
      }

      .total-container {
        align-items: flex-end;
        width: 100%;
        justify-content: flex-end;

        >div {
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .admin-details-container {
    .driven-container {
      >div {
        .column {

          &:nth-child(2),
          &:nth-child(4) {
            >div {
              &:nth-child(2) {
                width: 100%;
                margin-right: 0;
                margin-left: 0;

                .input-field {
                  padding-right: 2px;
                  margin-bottom: 0;
                  font-size: unit(14px);
                }
              }
            }
          }
        }
      }
    }

    .product-container {
      .product-content {
        display: block;
      }

      .total-container {
        align-items: flex-end;
        width: 100%;
        justify-content: flex-end;

        >div {
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .row-column {
    // padding-right: 16px;
    width: 50% !important;
    max-width: 50% !important;
    text-align: left;
    flex-direction: column;
    flex-wrap: wrap;

    .input-field {
      width: 80%;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  .row-column-input {
    width: 100%;

    .input-field {
      width: 50% !important;
    }
  }

  .custom-drivenkm-flex {
    margin-bottom: 64px;

    .field-heading {
      width: 100%;

      .row-column-input {
        width: 50%;

        .input-field {
          width: 50% !important;
        }
      }
    }
  }

  .product-row-column {
    display: flex;
    max-width: 50% !important;
    flex-direction: column;
    padding-bottom: 8px;
    text-align: left;
    flex: 0 0 50% !important;

    .field-heading {
      height: 35px;
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .input-label {
      height: 35px;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

}

@media screen and (max-width: 425px) {
  .admin-details-container .driven-container .column .driven-value {
    margin-bottom: 8px;
  }

  .require-attention {
    max-width: 50%;
  }

  .row-column {
    padding-right: 16px;
    width: 100% !important;
    max-width: 100% !important;
    text-align: left;
    flex-direction: column;

    .input-field {
      width: 80%;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  .product-row-column {
    display: flex;
    max-width: 100% !important;
    flex-direction: column;
    padding-bottom: 8px;
    text-align: left;
    flex: 0 0 100% !important;

    .field-heading {
      height: 35px;
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .input-label {
      height: 35px;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  .row-column-input {
    width: 50% !important;

    .input-field {
      width: 50% !important;
    }
  }
}

@media screen and (min-width: 1441px) and (max-width: 1600px) {
  .custom-drivenkm-flex {
    justify-content: unset !important;
  }

  .row-column {
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 769px) {
  .driven-container {
    .row-column {
      margin-bottom: 16px !important;
    }
  }
}