@import "../../styles/variables";

.products_drop_down {
  // margin: 5px 10px;

  .drop_down_indicator {
    > svg {
      fill: $primary-success;
      margin: 0 10px;
    }
  }
  // .products_drop_down__option:hover {
  //   background: $secondary-success;
  //   color: $user-name-color;
  // }

  // .products_drop_down__option--is-selected {
  //   background: $secondary-success;
  //   color: $user-name-color;
  // }
}
