/** @format */

@import '../../../styles/variables';

.admin-details-container {
  padding: unit(16px) unit(16px) unit(64px);

  &.loader-padding-container {
    padding: unit(64px) unit(16px) unit(64px);
  }

  .details-content {
    margin-top: unit(32px);

    p {
      font-size: unit(12px);
    }

    h3 {
      font-size: unit(14px);
      margin-bottom: unit(8px);
    }

    .column {
      width: 25%;
      word-break: break-word;

      .require-attention {
        background-color: $primary-success;
        color: white;
        padding: 16px;
        border-radius: 4px;
        box-shadow: 2px 2px 6px #00000054;
        width: 100%;
      }
    }
  }

  .driven-container {
    margin-top: unit(64px);
    width: 100%;

    // @media screen and (max-width: 1600px) {
    //   .custom-drivenkm-flex {
    //     flex-direction: column;
    //   }
    // }


    .width-15 {
      width: 15% !important;
    }

    .row-column {
      padding-right: 16px;
      width: 12%;
      max-width: 12%;
      text-align: left;
      flex-direction: column;

      .input-field {
        width: 80%;
        margin-right: 10px;
        margin-bottom: 0;
      }
    }

    .row-column-custom-width {
      max-width: 23%;
      width: 23%;

      .row-column-custom {
        width: 100% !important;
        flex-direction: column;
        flex: 1;

        .field-heading {
          width: 50%;
          height: 35px;
        }

        // .driven-value {
        //   // width: 50%;
        //   background: red;
        // }
      }
    }


    .column {
      padding-right: 0% !important;

      .field-heading {
        min-height: 35px;
      }

      h2 {
        font-size: unit(14px);
      }

      .driven-value {
        color: $primary-success;
        font-size: unit(14px);
        font-weight: 500;
        margin-top: 8px;
        // padding: 0 16px;
      }


      &:nth-child(2),
      &:nth-child(4) {
        >div {
          &:nth-child(2) {
            // margin-right: 10px;
            // margin-left: 10px;

            .input-field {
              padding-right: 2px;
              margin-bottom: 0;
              font-size: unit(14px);
            }
          }
        }
      }


      .loader-container {
        width: 65px;
        margin-left: 16px;
        height: 30px;
      }
    }
  }

  .product-container {
    margin-top: unit(64px);

    h2 {
      font-size: 1rem !important;
    }

    .product-content {
      display: flex;

      .column-container {
        width: 100%;

        .column {
          margin-top: unit(16px);
          background: transparent !important;
          padding: unit(8px) 0 !important;
          border-radius: 8px;

          .product-row-column {
            display: flex;
            max-width: 19%;
            flex-direction: column;
            padding-bottom: 8px;
            text-align: left;
            flex: 0 0 19%;

            .field-heading {
              height: 35px;
              font-size: 0.875rem;
              font-weight: 500;
              margin-bottom: 8px;
            }

            .input-label {
              height: 35px;
              font-size: 0.875rem;
              font-weight: 500;
            }
          }

          .product-row-column.comment {
            width: 23%;

            .comment-title {
              font-size: 0.875rem;
              font-weight: 500;
            }
          }

          .product-row-column:nth-child(4) {
            display: flex;

            .input-label {
              font-size: 0.875rem;
              width: 50%;
            }

            .input-field {
              width: 50%;
              margin-bottom: 0px;
            }
          }

          .loader-container {
            width: 65px;
            margin-left: 16px;
            height: 30px;

          }

          p {
            font-size: unit(14px);
          }

          .column-input {
            width: 37%;

            .input-label {
              font-size: unit(14px);
              color: inherit;
              margin: 0;
            }

            .input-field {
              width: 30%;
              font-size: unit(14px);
              margin-bottom: 0;
              background: $light-color;
              text-align: center;
            }
          }

          &:first-child {
            margin-bottom: unit(16px);
          }
        }
      }
    }
  }

  .total-container {
    margin-top: 32px;
    align-items: flex-end;
    width: 100%;
    padding-left: 16px;

    >div {
      text-align: right;
    }

    p {
      font-size: unit(18px);
    }

    .total-label {
      text-align: right;
      margin-right: 16px;

      .no-tax-label {
        font-size: unit(10px);
        color: $brand-secondary;
      }

      .total-value {
        margin-left: unit(8px);
        color: $primary-success;
        font-size: unit(35px);
        font-family: 'Bahnschrift';
      }

      .project-btn {
        margin-left: unit(20px);
        font-size: unit(19px);
        padding: unit(14px) unit(50px);
        height: unit(55px);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .require-attention {
    width: 25%;
    max-width: 30%;
  }



  .admin-details-container {
    padding-bottom: 0;

    .details-content,
    .driven-container {

      margin-top: 0rem;
    }


    .details-content .column,
    .driven-container .column {
      width: 100%;
      padding-right: 0;
      float: left;
      margin-top: 20px;
    }

    .product-container {
      margin-top: 2rem;
      float: left;
      width: 100%;

      .total-container {
        align-items: flex-end;
        width: 100%;
        justify-content: flex-start;
        padding: 0;

        >div {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .total-label {
          display: flex;

          .total-value {
            margin-left: 10px;
            margin-right: 10px;
            font-size: 1.8rem;
          }
        }

        p {
          text-align: left;
        }
      }

      .product-content {
        .column-container {
          .column {
            // display: block !important;

            &>div {
              width: 100% !important;
              margin-bottom: 7px;
            }

            .column-input {
              .input-label {
                width: 60%;
              }

              .input-field {
                width: 30%;
                padding: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}



@media screen and (max-width: 1024px) {
  .admin-details-container {
    .driven-container {
      >div {
        .column {

          &:nth-child(2),
          &:nth-child(4) {
            >div {
              &:nth-child(2) {
                width: 100%;
                margin-right: 0;
                margin-left: 0;

                .input-field {
                  padding-right: 2px;
                  margin-bottom: 0;
                  font-size: unit(14px);
                }
              }
            }
          }
        }
      }
    }

    .product-container {
      .product-content {
        display: block;
      }

      .total-container {
        align-items: flex-end;
        width: 100%;
        justify-content: flex-end;

        >div {
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }
}

@media screen and (min-width: 1441px) and (max-width: 1599px) {
  .custom-drivenkm-flex {


    .row-column {
      width: auto !important;
      max-width: 100% !important;
      text-align: left;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .row-column-custom-width {
      width: 25% !important;
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1440px) {
  .custom-drivenkm-flex {
    justify-content: unset !important;

    .row-column {
      width: 25% !important;
      max-width: 25% !important;
      text-align: left;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .row-column-custom-width {
      width: 25% !important;
    }
  }
}

@media screen and (max-width: 1200px) {
  .row-column {
    width: 50% !important;
    max-width: 50% !important;
    text-align: left;
    flex-direction: column;
    flex-wrap: wrap;

    .input-field {
      width: 80%;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  .custom-drivenkm-flex {
    margin-bottom: 64px;
  }

  .product-row-column {
    display: flex;
    max-width: 50% !important;
    flex-direction: column;
    padding-bottom: 8px;
    text-align: left;
    flex: 0 0 50% !important;

    .field-heading {
      height: 20px !important;
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .input-label {
      height: 20px !important;
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .comment-title {
      margin-bottom: 8px;
    }
  }

  .product-row-column.comment p {
    padding: 0;
  }
}

@media screen and (max-width: 425px) {
  .require-attention {
    max-width: 50%;
  }

  .row-column {
    padding-right: 16px;
    width: 100% !important;
    max-width: 100% !important;
    text-align: left;
    flex-direction: column;

    .input-field {
      width: 80%;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  .product-row-column {
    display: flex;
    max-width: 100% !important;
    flex-direction: column;
    padding-bottom: 8px;
    text-align: left;
    flex: 0 0 100% !important;

    .field-heading {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .input-label {
      height: 35px;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
}