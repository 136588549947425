/** @format */

@import '../../../../styles/variables';

.details-container {
  // border: 2px solid $brand-primary;
  padding: unit(16px) unit(16px) unit(64px);
  border-radius: 14px;
  // border: 2px solid #71BF5D;

  .details-content {
    margin-top: unit(32px);

    p,
    h3 {
      font-size: unit(14px);
      @media screen and (max-width: $tab-device) {
        font-size: 0.875rem;
      }
    }

    h3 {
      margin-bottom: unit(8px);
    }

    .column {
      width: 22%;

      .urgency {
        color: $primary-success;
      }
    }
  }

  .driven-container {
    margin-top: unit(64px);
    width: 100%;

    .column {
      h2 {
        font-size: 0.875rem;
      }
      .driven-value {
        color: $primary-success;
        margin:0 unit(16px);
        width: 20%;
        .input-field  {
          margin-bottom: 0;
        }
      }
    }
  }

  .product-container {
    margin-top: unit(64px);

    h2{
      font-size: 1rem;
    }

    .product-content {
      .column-container {
        width: 100%;
        margin-right: 32px;
        .column {
          margin-top: unit(16px);
          background: transparent;
          padding: unit(8px) 0;
          border-radius: 8px;
          width: 100%;
          p {
            font-size: 0.875rem;
          }

          .column-input {
            width: 250px;
            .input-label {
              font-size: unit(14px);
              color: inherit;
              margin: 0;
            }

            .input-field {
              width: 70%;
              margin-bottom: 0;
              background: $light-color;
            }
          }

          &:first-child {
            margin-bottom: unit(16px);
          }

          > div {
            &:nth-child(1)  {
              width: 45%;
            }
            &:nth-child(2)  {
              width:35%;
              text-align: left;
            }
            &:nth-child(3)  {
              width: 15%;
            }
          }
        }

        .button-container {
          width: 10%;

          .save-btn {
            margin-right: 16px;
            svg {
              .save-icon  {
                fill: $primary-success;
              }
            }
          }
        }
      }

      .total-container {
        width: 10%;
        p {
          font-size: unit(18px);
        }
        .total-value {
          margin-left: unit(8px);
          color: $primary-success;
          font-size: unit(32px);
          font-family: 'Bahnschrift';
        }

        .project-btn {
          margin-left: unit(8px);
          font-size: unit(19px);
          padding: unit(16px) unit(42px);
        }
      }
    }
  }
}


@media screen and (max-width:1024px) {
  .details-container {

      .product-container {

          .product-content {
              display: block;
          }

          .total-container{
            align-items: flex-end;
            width: 100%;
            justify-content: flex-end;  

             > div{
              flex-direction: column;
              align-items: flex-end;
             }
          }
      }
  }
}
@media screen and (max-width:$tab-device){
  .details-container
    { 
      
      float:left;
      padding-bottom: 0;

      .details-content,  .driven-container{
        display: block !important;
        float:left;
        margin-top:0rem;
      } 

  
 
      .details-content .column, .driven-container .column {
          width: 100%;
          padding-right: 0;
          float: left;
          margin-top:20px;
      }
     .driven-container .column{
        margin-top:10px;
      
      }
      .product-container 
      
          {
            margin-top: 2rem;
            float: left;
            width:100%;
          
            .total-container
              {
                align-items: flex-end;
                width: 100%;
                justify-content: flex-start;
                padding: 10px;

                > div {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                  }
                
                .total-label{
                  display: flex;
                  .total-value{
                    margin-left:10px;
                    margin-right:10px;
                    font-size:1.8rem;
                  }
                }
                p {
                  text-align: left;
                }
            }

            .product-content{
              .column-container{
                .column{
                  
                  display: block  !important;

                  &> div{
                      width:100% !important;
                      margin-bottom:7px;
                  }

                  .column-input{
                    .input-label{
                      width: 60%;
                    }
                    .input-field{
                      width: 30%;
                      padding:0.5rem;
                    }
                  }
                }
              } 
            }
      }
  }
} 

