/** @format */

@import '../../../styles/variables';

.overview-card-container {
  > div {
    min-width: 23%;
  }
  .overview-card {
    background: $brand-primary;
    padding: unit(16px);
    border-radius: 14px;
    position: relative;

    &.active-tab {
      border: 2px solid $primary-success;
    }
 

    .overview-card-title {
      font-size: unit(18px);
    }

    .card-amount {
      color: $primary-success;
      font-size: unit(56px);
      font-family: 'Bahnschrift';
      line-height: 1;
      position: absolute;
      right: 20%;
      bottom: 0;
    }

    .bg-img {
      position: absolute;
      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .top-img  {
      top: 0;
      right: 10%;
      width: 70%;
      max-width: 200px;
    }

    .bottom-img {
      right: 10%;
      width: 80%;
      max-width: 250px;
      bottom: 0;
    }
  }

  .small-card-container {
    .card-icon {
      width: unit(24px);
      margin-right: unit(10px);

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .overview-card  {

      .card-amount  {
        color: $heading-text;
        margin-left: unit(10px);
              position: relative;
      right: auto;
      bottom: auto;
      }

      &:nth-child(1)  {
        margin-bottom: unit(16px);
      }
    }
  }
}

@media screen and (max-width:1024px){
  .overview-card-container
    { 
      .overview-card 
      
      {
        .card-amount 
          {
            font-size: 3rem;
            right: 8%;
            bottom: 4%;
          }

          .overview-card-title {
            font-size: 1rem;
          }
        } 

      }
    
}
@media screen and (max-width:$tab-device) {
  .overview-card-container 

  {
          .overview-card{
            overflow: hidden;
            margin-bottom: 15px;
            height:unit(64px);
            padding: 0.5rem 1rem;

            &> div{
              display: flex;
              align-items: center;
              height: 100%;
              justify-content: space-between;
            }
            .card-amount{
              right:0;
              font-size: 2rem;
              position: relative;
            }

            .top-img, .bottom-img{
              display:none;
            }
          }
          .small-card-container {
              .card-icon {
                img{
                  height:16px;
          
              }
              }
            }
      }
    .overview-card-container.d-flex {
        display: block !important;
    }
}