/** @format */

@import '../../styles/variables';

.setting-content {
  height: 100%;
  position: relative;

  .form-container {
    position: absolute;
    // top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    // height: calc(100% - 100px);
    height: 100%;

    .profile-form {
      height: calc(100% - 80px);
      overflow-y: auto;
      padding: 0 16px;
    }


    .loader-container {
      width: 65px;
      margin-left: 16px;
      height: 30px;
    }
    
    .title {
      font-family: 'Bahnschrift';
      font-weight: 500;
      font-size: unit(22px);
      margin-bottom: unit(16px);
    }

    .business-area {
      font-size: unit(14px);
    }

    .img-column {
      width: 100px;

      .uploaded-img-container {
        background: $brand-secondary;
        border-radius: 7px;
        width: 100%;
        height: 100px;
        overflow: hidden;
        .uploaded-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }
    }

    .input-column {
      width: calc(100% - 120px);
      .input-field {
        margin-bottom: 0;
      }
    }

    .remove-account {
      color: $error-color;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@media screen and (max-width:1024px){

}

@media screen and (max-width:767px){
  .setting-content .form-container {
    position: relative;
    top: initial;
    left: initial;
    transform: none;
    width: 100%;
}
.setting-content .form-container .remove-account{
  font-size:0.8rem;
}
}