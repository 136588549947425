@import '../../styles/variables';

.task-main-container  {
  position: relative;
  .headerContainer {
      margin-bottom: unit(32px);
  
      .taskHeading {
        margin-bottom: 0;
      }

      .newTaskImgContainer  {
        width: 32px;
        margin-top: 5px;
        margin-right: 16px;
        margin-left: 16px;

        a {
          display: block;
        }
      }
    }
}

@media screen and (max-width:1024px){
  .task-main-container {
     h1 {
    margin:unit(15px) 0 !important;
    text-align: center;
    }
  }

  
}