/** @format */

@import '../../styles/variables';
.product-container {
  position: relative;
  .btn-container {
    margin-bottom: unit(32px);
    margin-top: unit(32px);

    .project-btn {
      font-size: unit(14px);
      font-family: 'Montserrat', sans-serif;
      border-radius: 7px;
    }

    .input-container {
      width: unit(200px);

      .input-field {
        font-size: unit(14px);
        margin-bottom: 0;
        margin-left: unit(16px);
      }
      
    }
  
  }


  .product-btn-area {
   margin-bottom: unit(30px);
   display: inline-block;
   width: 100%;
   text-align: right;

   button {
     margin-left: unit(10px);
   }
 }

  // .product-list-container {
  //   border: 2px solid $brand-primary;
  //   padding: unit(8px) unit(32px);
  //   border-radius: 14px;
  //   margin-bottom: unit(32px);

  //   .id-model-container {
  //     font-size: unit(14px);

  //     p {
  //       width: unit(150px);
  //       text-align: center;

  //       &:first-child {
  //         padding: 0 unit(32px);
  //         border-left: 2px solid $brand-primary;
  //         border-right: 2px solid $brand-primary;
  //       }
  //       &:last-child {
  //         margin-left: unit(32px);
  //       }
  //     }
  //   }
  // }

  // .dark-list-container {
  //   padding-top: unit(24px);
  //   padding-bottom: unit(24px);
  //   margin-bottom: unit(16px);
  //   background: $brand-primary;

  //   .product-name-container {
  //     width: unit(200px);
  //   }
    

  //   .id-model-container {
  //     p {
  //       &:first-child {
  //         border-left: 2px solid white;
  //         border-right: 2px solid white;
  //       }
  //     }
  //   }
  // }
}

.task-list {
  .list-header  {
    .product-name {
      width: 40%;
      padding: 0 16px;
      .dark-border {
        border-left: 0;
      }
    }

    .product-id,.product-model{
      width: 15%;
      text-align: center;
      // border-left: 2px solid $brand-primary;
    }
  }

  .product-name-container {
    width: 70%;
    padding: 0 16px;

    .product-title  {
      font-weight: 500;
      font-size: unit(14px);
    }
  }

  .product-btn-container  {
    width: 20%;
  }

  .product-btn-container {
      .product-btn {
        font-weight: normal;
        font-size: unit(14px);
        color: $body-text;
      }
      .btn-edit {
        margin-right: unit(16px);
      }

      .btn-delete {
        color: $error-color;
      }
    }
}
@media screen and (max-width:1024px) {
  .product-container{
  .product-btn-area{
    text-align: center;
  }
}
}
@media screen and (max-width:$tab-device){
 .product-container
 { 
   .task-list 
    {
      .product-name-container {
        width:80%;
        padding: 0 7px 0 0;
      }
      .product-btn-container {
        width: 20%;
        display: flex;
        flex-direction: column;
        text-align: right;
        justify-content: flex-end;

        .product-btn {
          font-size: 0.8rem;
          text-align: right;
          margin-right:0;
      }

    }
    .list-header {

      .header-column {

          &:first-child{
            display: inline-block;
            border-left:0;
            padding-left:0;
          }
        }
      }

      tbody{
      .list-content{

        .content-td{
          font-size: 0.8rem;
          
          &:nth-child(2), &:nth-child(3){
            width:50%;
            text-align: left;
            }
            
          }
        }
      }
    }
    .product-btn-area {
      justify-content: center;
     }
  }
}