/** @format */
@import '../../styles/variables';

.invite-main-container {
  height: 100vh;
  position: relative;
  background: $brand-primary;

  .invite-container {
    padding: unit(32px) unit(64px);
    background: $light-color;
    box-shadow: 0px 32px 74px #0000000d;
    border-radius: 17px;
    width: 35%;

    h3 {
      margin-bottom: 32px;
    }

    .input-label {
      font-size: unit(10px);
      text-align: left;
      display: block;
      padding-left: unit(10px);
    }

    .project-btn {
      margin-top: unit(32px);
    }
  }
}
