/** @format */

@import '../../../../styles/variables';

.employee-task-list {
  color: inherit;
  border-collapse: separate;
  border-spacing: 0 1em;

  .list-header {
    overflow: hidden;
    border: 2px solid $brand-primary;
    border-radius: 14px;

    .header-column {
      padding: unit(16px) 0;
      vertical-align: middle;
      border: 0;
      font: inherit;
      font-size: unit(14px);
      border-top: 2px solid $brand-primary;
      border-bottom: 2px solid $brand-primary;

      &:first-child {
        border-left: 2px solid $brand-primary;
        padding-left: 16px;
      }

      &:last-child {
        border-right: 2px solid $brand-primary;
      }

      .dark-border {
        border-left: 2px solid $brand-primary;
      }

      &:first-child {
        .dark-border {
          width: 35px;
        }
      }

    }

    .Tasks {
      width: 28%;

      .dark-border {
        border-left: 0;
      }
    }

    .Arrived,
    .Completed,
    .Worker,
    .Status {
      width: 10%;
      text-align: center;
    }

    .date-column {
      width: 20%;
      text-align: center;
    }
  }

  .list-content {
    .content-td {
      border: 0;
      padding: 0;
      vertical-align: middle;
      text-align: center;
      padding: unit(16px) 0;
      font-size: unit(14px);
      background: $brand-primary;

      .custom-task-width-mytasks {
        padding-left: 16px;

        .custom-toggle-icon {
          padding: 0 16px;
        }
      }

      .white-border {
        border-left: 2px solid white;
      }

      .dropdown-btn-container {
        width: 80%;
        margin: 0 auto;

        #dropdown-basic {
          background: white;
          border: 0;
          width: 100%;
          border-radius: 50px;
          outline: 0;
          box-shadow: none;
          font: inherit;
          color: $body-text;
          text-align: left;
          height: 33px;
          position: relative;
        }
      }

      .dropdown-menu {
        border: 0;

        .dropdown-item {
          color: inherit;

          &:hover,
          &.active {
            background: $primary-success;
          }
        }
      }

      .dropdown-toggle {
        height: 100%;

        &::after {
          position: absolute;
          content: '';
          top: 45%;
          right: 10px;
          border-top: 0.3em solid $heading-text;
        }
      }

      .rdt {
        width: 90%;
        margin: 0 auto;
      }
    }
  }

  .collapse-content {
    .collapse-row {
      border: 0;
      padding: 0;
    }
  }

    .editIcon {
      width: 20px;
      margin-left: 12px;
    }
}

//empoyee task list - MY TASKS TAB
.employee-task-list {
  .expanded-column {
    .custom-row {
      text-align: center;

      .custom-task-width-mytasks {
        background-color: transparent !important;
        padding-left: 16px !important;

        .custom-toggle-icon {
          padding: 0 16px;
        }
      }

      .custom-task-w-10 {
        border-left: 2px solid white !important;
        background-color: transparent !important;
      }

      .custom-task-w-20 {
        border-left: 2px solid white !important;
        background-color: transparent !important;

        .rdt {
          width: 80%;
          margin: 0 auto;
        }
      }

      .custom-task-w-1 {
        background-color: transparent !important;
        margin: 0 auto;
      }
    }
  }
}


@media screen and (max-width: 1340px) {
  .employee-task-list {
    .custom-row {
      .custom-task-w-1 {
        .status-div {
          width: 100%;
          margin: 0 2px;
        }
      }
    }
  }
}

@media screen and (max-width:1024px) {
  .employee-task-list .list-header .header-column:first-child .dark-border {
    width: auto !important;
  }

  .employee-task-list {
    .list-header {
      .header-column {
        padding: unit(16px) unit(15px);
      }
    }

    .list-content {

      .content-td {

        .dropdown-btn-container {
          #dropdown-basic {
            font-size: 9.5px;
          }
        }
      }

    }
  }
}

@media screen and (max-width:$tab-device) {
  .task-list-container {
    overflow-x: scroll;
  }

  table.task-list.table,
  table.task-list.table thead,
  table.task-list.table .list-header {
    display: block;
  }

  .employee-task-list {
    .list-header {
      display: block;
      overflow: hidden;
      border: 0;
      border-radius: 0;
      margin-bottom: 20px;

      .Tasks {
        width: 28%;

        .dark-border {
          // border-left: 2px solid $brand-primary !important;
        }
      }


      .header-column {
        padding: 0;
        float: left;
        width: auto !important;
        text-align: center;
        margin: 1%;
        border: 0;

        .dark-border {
          padding: unit(8px);
          border: 1px solid $body-text;
          font-size: unit(12px);

        }

        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          border-left: 1px solid $body-text;
        }

        &:last-child {
          border-right: 0;
        }

      }

    }

    .list-content {

      .content-td {
        font-size: 0.7rem;

        h3 {
          font-size: 0.875rem;

          @media screen and (min-width: 769px) {
            font-size: 1rem;
          }
        }


      }

    }

  }

  tbody {
    display: block;

    .list-content {
      padding: 10px;
      float: left;
      width: 100%;
      background-color: $brand-primary;
      margin-bottom: unit(20px);

      .content-td {
        display: block;
        float: left;
        width: 50%;
        padding: 10px 0;
        font-size: 0.8rem;

        &:nth-child(1) {
          width: 100%;
          padding: 0;
          font-size: 0.875rem;
        }

        &:nth-child(2) {
          width: 100%;
          text-align: center;

        }

        .white-border {
          border: 0;

          .loader-container {
            margin-left: 16px;
            height: 30px;
          }
        }

        h3 {
          font-size: 1rem;
        }

        h6 {
          font-size: 0.875rem;
          margin-bottom: 5px;
        }

      }
    }

  }
}

.status-div {
  font-size: 100%;
}



@media screen and (max-width: 992px) {
  .employee-task-list {
    .list-header {

      .Tasks {
        width: 22%;

        .dark-border {
          border-left: 0;
        }
      }

      .Arrived,
      .Completed,
      .Worker,
      .Status {
        width: 10%;
        text-align: center;
        // border-left: 2px solid $brand-primary;
      }

      .date-column {
        width: 24%;
        text-align: center;
        // border-left: 2px solid $brand-primary;
      }
    }

  }
}


@media screen and (max-width: 1200px) {
  .employee-task-list {
    .list-header {

      .Tasks {
        width: 20%;

        .dark-border {
          border-left: 0;
        }
      }

      .Arrived,
      .Completed,
      .Worker,
      .Status {
        width: 10%;
        text-align: center;
        // border-left: 2px solid $brand-primary;
      }

      .date-column {
        width: 26%;
        text-align: center;
        // border-left: 2px solid $brand-primary;
      }
    }

  }
}

@media screen and (max-width:$tab-device) {
  .employee-task-list {
    .collapse-row {
      border-top: none;

      h3,
      h2,
      p {
        font-size: 0.875rem;
      }

      h6 {
        font-size: 0.875rem;
        margin-bottom: 5px;
      }

      .custom-row {
        text-align: center;
        display: flex;

        .custom-task-width-mytasks {
          background-color: transparent !important;
          padding-left: 16px !important;
          font-size: 0.875rem;

          .custom-toggle-icon {
            padding: 0 16px;
          }

          border-right: none;
        }

        .custom-task-w-10 {
          padding: 16px 0 !important;
          border-left: none !important;
          background-color: transparent !important;
          font-size: 0.875rem;
        }

        .custom-task-w-20 {
          padding: 16px 0 !important;
          border-left: none !important;
          background-color: transparent !important;
          font-size: 0.875rem;

          .rdt {
            width: 80%;
            margin: 0 auto;
          }
        }

        .custom-task-w-1 {
          background-color: transparent !important;
          margin: 0 auto;
          font-size: 0.875rem;

          .white-border {
            border-left: none !important;

            .status-div {
              width: 80%;
              margin: 0 auto;
              font-size: 0.875rem;
            }
          }
        }
      }
    }

  }
}

// My tasks
.employee-task-list {
  .expanded-column {
    .custom-row {
      flex-wrap: wrap;

      .edit-icon:hover {
        cursor: pointer !important;
      }

    }

    .employee-details-container {

      .driven-container {
        .column {
          h2 {
            font-size: 0.875rem;

            @media screen and (max-width: $tab-device) {
              font-size: 0.875rem;
            }
          }
        }
      }

      .product-container {
        .column-container {
          .column {
            padding: 0.5rem 0 !important;

          }
        }

        .dropdown {
          .dropdown-menu {
            .dropdown-item {
              font-size: 0.875rem !important;

              &:hover {
                background-color: $primary-success;
              }
            }
          }

          .btn-primary:not(:disabled):not(.disabled).active,
          .btn-primary:not(:disabled):not(.disabled):active,
          .show>.btn-primary.dropdown-toggle {
            background-color: transparent !important;
            background: white;
            border: 0;
            width: 100%;
            border-radius: 50px;
            outline: 0;
            box-shadow: none;
            font: inherit;
            color: $body-text;
            text-align: left;
            height: 33px;
            position: relative;
          }



          .btn-primary {
            background: white;
            border: none;
            position: relative;
            border-radius: 50px;

            &:focus {
              background-color: white;
              outline: none;
              box-shadow: none;
            }

            &::after {
              position: absolute;
              top: 50%;
              right: 10%;
              color: black;
              z-index: 999;
              transform: translateY(-50%)
            }


            .input-field {
              margin-bottom: 0;
              font-size: 0.875rem;

              &:hover {
                cursor: pointer;
              }
            }


          }

        }
      }
    }

    .custom-row .custom-cell-container:nth-child(1) {
      font-size: 0.875rem;
      width: 29%;

      @media screen and (max-width:$tab-device) {
        width: 100%;
        flex: 0 0 100%;
      }
    }

    .custom-row .custom-cell-container:nth-child(2) {
      width: 10%;

      @media screen and (max-width:$tab-device) {
        width: 100%;
        flex: 0 0 100%;
        font-size: 0.875rem;
      }
    }

    .custom-row .custom-cell-container:nth-child(3) {
      width: 10%;

      @media screen and (max-width:$tab-device) {
        width: 50%;
        flex: 0 0 50%;
        font-size: 0.875rem;
      }
    }

    .custom-row .custom-cell-container:nth-child(4) {
      width: 20%;

      @media screen and (max-width:$tab-device) {
        width: 50%;
        flex: 0 0 50%;
        font-size: 0.875rem;
      }
    }

    .custom-row .custom-cell-container:nth-child(5) {
      width: 20%;

      @media screen and (max-width:$tab-device) {
        width: 50%;
        flex: 0 0 50%;
        font-size: 0.875rem;
      }
    }

    .custom-row .custom-cell-container:nth-child(6) {
      width: 11%;

      @media screen and (max-width:$tab-device) {
        width: 50%;
        flex: 0 0 50%;
        font-size: 0.875rem;
      }
    }
  }
}