@import '../../../styles/variables'; 

.employee-dec-container {
  margin-top: unit(64px);
  padding-left: unit(16px);
  padding-right: unit(16px);
  padding-bottom: unit(48px);

  .column {
    margin-bottom: 16px;
    width: 49%;
    .aligned-label {
      width: 170px;
    }
    label {
      white-space: nowrap;
    }
    .custom-input {
      .input-label {
        width: 170px;
      }
    }
  }

  p {
    font-size: unit(17px);
    margin-bottom: unit(8px);
  }
  
}

.total-container  {
  margin-top: unit(64px);
  padding-left: unit(16px);
  padding-right: unit(16px);
  .total-value  {
    font-size: unit(22px);
    font-family: 'Bahnschrift';
    margin-bottom: 0;
  }

     .btnIcon {
       width: 25px;
       margin-right: 12px;
       display: block;
     }
}

@media screen and (max-width:$tab-device) {
  .employee-dec-container {
    margin-top: 0;
    padding-left: 0;
    padding-bottom: 0;

    .column {
      flex-wrap: wrap;
      margin-bottom: 16px;
      width: 100%;
      > div {
        &:nth-child(1){
          width: 100%;
          margin-right: 5px;
        }
        &:nth-child(2){
          width: 100%;
          margin-right: 5px;
          margin-bottom: 16px;
        }
        &:nth-child(3){
          width: 100%;
        }
      }
    }
  
    .address-container {
      > div {
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          width: 100%;
        }
        &:nth-child(4) {
          width: 100%;
        }
      }
    }
 
  }
  .employee-dec-container .total-container {
    margin-top: 1.5rem;
  }
}