/** @format */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');

// @import url('https://db.onlinewebfonts.com/c/0a6ee448d1bd65c56f6cf256a7c6f20a?family=Bahnschrift');

@import './_variables';
@import './_font';

* {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-type: none;
  outline: 0;
  border: 0;
  box-shadow: none;
}

body {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: unit(16px);
  color: $body-text;
}

.input-field {
  background: $brand-primary;
  width: 100%;
  padding: unit(8px);
  font-family: inherit;
  font-size: unit(16px);
  font-weight: 400;
  margin-bottom: unit(16px);
  border-radius: unit(8px);
  color: black;
}

.disabled {
  cursor: not-allowed;
}

::placeholder {
  color: $brand-secondary;
}

.search-input {
  background: $brand-primary url(../images/search-icon.png);
  background-position: left unit(16px) center;
  background-repeat: no-repeat;
  background-size: 16px;
  padding-left: unit(48px);
}

.error-message {
  text-align: left;
  margin-bottom: unit(8px);
  color: $error-color;
}

.project-btn {
  background: $primary-success;
  font-family: 'Bahnschrift';
  color: $light-color;
  font-size: unit(19px);
  padding: unit(8px) unit(24px);
  border-radius: unit(120px);
}

.selectElement {
  margin-left: 16px;
  color: $body-text;
  border: 0;
  min-width: 149px;
  text-align: right;
  background: #f6f6f6;
  border-radius: 8px;
  left: 0 !important;

  .dropdown-item {
    white-space: pre-wrap;
    word-break: break-all;
  }
}

h1 {
  font-family: 'Bahnschrift';
  font-size: unit(35px);
  margin: 0;
}

h2 {
  font-size: unit(18px);
  font-weight: 500;
  margin: 0;
}

h3 {
  font-size: unit(16px);
  font-weight: 600;
  margin: 0;
}

p {
  margin: 0;
  @media screen and (max-width: $tab-device) {
    font-size: 0.875rem;
  }
}

button {
  background: transparent;
  box-shadow: none;
  border: 0;
  font: inherit;
}

.disabled-btn {
  background: $brand-secondary;
}


 .automcompleteitems {
   position: absolute;
   left: 0;
   top: 42px;
   background: white;
   z-index: 999;
   width: max-content;
   max-height: 150px;
   overflow-y: auto;
   box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.206), 0 2px 10px 0 rgba(0, 0, 0, 0.098);
   
   .automcompleteitem {
     padding: 4px 16px;
     color: $heading-text;
     cursor: pointer;

     &:hover  {
      background-color: $brand-primary;
     }
   }
 }

// overwrite modal

.modal-dialog {
  .modal-content {
    .modal-header {
      border: 0;
    }
  }
}

// layout of sidebar and content

.main-container {
  padding: unit(16px);
  height: 100vh;

  .sidebar-container {
    flex: 0 0 unit(250px);
    max-width: unit(250px);
  }

  .section-main-container {
    width: calc(100% - 250px);
    padding: unit(16px) unit(16px) unit(16px) unit(32px);
    @media screen and (max-width: 1040px) {
      padding: unit(16px) unit(16px) unit(16px) unit(16px);
    }
  }
}

//remove button
.removeInput {
  background: transparent;
  .remove-cross-btn {
    fill: $error-color;
    font-weight: 800;
  }
}

// product-visit-add-btn
.product-visit-add-btn {
  fill: $primary-success;
}

.primary-btn-fill {
  fill: $primary-success;
}

.trash-icon {
  color: $error-color;
}

.mobile-nav {
  display: none;
}
@media screen and (max-width: 1024px) {
  h1 {
    font-size: 1.6rem;
    margin: unit(16px) 0 !important;
    text-align: center;
  }
  body.noscroll {
    overflow: hidden;
  }
  .mobile-nav {
    display: block;
    z-index: 999999;
    position: absolute;
    background: $light-color;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
    box-shadow: 0 0 5px 2px #efefef;
  }
  .mobile-nav img {
    height: unit(80px);
    padding: unit(15px) unit(0px);
  }
  .wrapper-menu {
    width: unit(25px);
    height: unit(20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    cursor: pointer;
    transition: all 0.5s ease-out;
    z-index: 999;
    top: unit(32px);
    left: 1rem;
  }
  .line-menu {
    background-color: $primary-success;
    border-radius: 0px;
    width: 100%;
    height: 4px;
    transition: all 0.3s ease-in-out;
    transform: rotate(0);
  }
  .active .line-menu.start {
    transform: rotate(48deg);
    top: 3px;
    position: relative;
    width: 50%;
    left: 2px;
  }
  .active .line-menu.second {
    transform: rotate(-45deg);
    top: 0px;
    position: relative;
  }
  .active .line-menu.end {
    transform: rotate(-133deg);
    top: -4px;
    width: 55%;
    position: relative;
    left: unit(9.6px);
  }
  .main-container .sidebar-container {
    left: unit(-250px);
    position: fixed;
    transition: all 0.5s ease-in-out;
    top: unit(80px);
    z-index: 9;
  }
  .main-container .sidebar-container.openMenu {
    left: 0;
  }
  .lang-dropdown {
    position: relative;
  }
  .main-container,
  .main-container .section-main-container {
    width: 100%;
  }
  .main-container .section-main-container {
    padding: unit(80px) unit(0px) unit(32px);
  }

  .mobile-nav {
    > div:first-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .profile-form {
    input.input-field {
      margin-bottom: 0;
    }
    .input-label {
      margin: 0;
    }
  }
}

@media screen and (max-width: $tab-device) {
  .mb-4 {
    margin-bottom: 1rem !important;
  }
  .profile-form input.input-field {
    font-size: 0.8rem;
  }
  .project-btn {
    font-size: 1rem;
  }
}
//input
// .input-label {
//   font-size: unit(10px);
//   text-align: left;
//   display: block;
//   padding-left: unit(10px);
// }

@media (min-width: 576px) {
  .employee-modal {
    max-width: 80%;
    margin: 1.75rem auto;
  }
}

.user-search {
  padding: 0 0;
  background: white;

  @media screen and (max-width: $tab-device) {
    font-size: 0.875rem;
  }
}
