/** @format */

@import '../../../../styles/variables';

.employee-details-container {
  .width-50.product-model-container {
    display: flex;

    .product-model-item {
      margin-left: 8px;
    }
  }

  // border: 2px solid $brand-primary;
  padding: unit(16px) unit(16px) unit(64px);
  // border-radius: 14px;
  // border: 2px solid #71BF5D;

  &.loader-padding-container {
    padding: unit(64px) unit(16px) unit(64px);
  }

  .driven-content-row {
    width: 100%;
  }

  .req-title {
    font-size: 1rem;
    font-weight: 500;
  }

    .plusIcon {
      width: 25px;
      margin-left: 10px;
      margin-top: -3px;
    }

    .remove-cross-btn {
      width: 25px;
    }

  .details-content {
    margin-top: unit(32px);

    p,
    h3 {
      font-size: unit(14px);
    }

    h3 {
      margin-bottom: unit(8px);
    }

    .column {
      width: 22%;

      .urgency {
        color: $primary-success;
      }
    }
  }

  .driven-container {
    margin-top: unit(64px);
    width: 100%;

    .add-visit {
      font-size: 1rem;
      color: $body-text;
      font-weight: 500;
    }

    .column {
      width: 25%;

      .driven-value {
        color: $primary-success;
        margin: 0 unit(16px);
        width: 30%;
        padding-top: 8px;

        .input-field {
          margin-bottom: 0;
          padding-right: 0;
          font-size: 0.875rem;
        }
      }

      .loader-container {
        width: 65px;
        margin-left: 16px;
        height: 30px;
      }
    }

    .column:nth-child(4) {
      .driven-value {
        max-width: 25ch;
        width: 25ch;
        max-height: 100%;
        word-break: break-word;
        padding-top: 8px;
      }

      .driven-value-40 {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        word-break: break-word;
        padding-top: 8px;

        textarea {
          width: 100%;
        }
      }

    }
  }

  .required-driven-container {

    .column {
      .loader-container {
        width: 65px;
        margin-left: 16px;
        height: 30px;
      }

      &:nth-child(1) {
        width: 40%;
      }

      &:nth-child(2) {
        width: 50%;
      }


      .driven-value {
        width: auto;

        .requried-text {
          color: $error-color;
        }
      }
    }
  }

  .product-container {
    margin-top: unit(64px);

    h2 {
      font-size: 1rem;
    }

    p {
      font-size: 0.875rem;
    }

    .heading {
      font-weight: 500;
      margin-bottom: 8px;
    }

    .product-content {
      .custom-product-container {
        .textArea-container {
          textarea {
            // width: 150px;
            // max-width: 200px;
            min-height: 65px !important;
            max-height: 200px !important;
            font-size: 0.875rem;
            margin: 0 8px;
          }
        }
      }

      .column-container {

        // width: 85%;
        // margin-right: 32px;
        .column {
          margin-top: unit(16px);
          // background: $brand-primary;
          padding: 0;
          border-radius: 8px;
          width: 90%;

          .column-input {
            width: 250px;

            .input-label {
              font-size: unit(14px);
              color: inherit;
              margin: 0;
            }

            .loader-container {
              width: 65px;
              margin-left: 16px;
              height: 30px;
            }

            .input-field {
              width: 70%;
              margin-bottom: 0;
              background: $light-color;
            }
          }

          &:first-child {
            margin-bottom: unit(16px);
            background: transparent;
          }

          >div {
            &:nth-child(1) {
              width: 24%;
            }

            &:nth-child(2) {
              width: 24%;
              text-align: left;
              // flex-direction: column;
            }

            &:nth-child(3) {
              width: 24%;
              // flex-direction: column;

              .input-field {
                width: 70%;
                margin: 0 unit(8px);
                padding-right: 2px;
              }
            }

            &:nth-child(4) {
              width: 24%;
              // flex-direction: column;
            }
          }
        }

        .button-container {
          width: 10%;

          button {
            margin-left: 8px;
            padding: 8px 11px;
            font-size: 14px;
            border-radius: 4px;
          }

          // .save-btn {
          //   margin-right: 16px;
          //   svg {
          //     .save-icon  {
          //       fill: #c5c5c5;
          //     }
          //     .updated  {
          //       fill: $primary-success;
          //     }
          //   }
          // }
        }
      }

      .total-container {

        // width: 10%;
        p {
          font-size: unit(18px);
        }

        .total-value {
          margin-left: unit(8px);
          color: $primary-success;
          font-size: unit(32px);
          font-family: 'Bahnschrift';
        }

        .project-btn {
          margin-left: unit(8px);
          font-size: unit(19px);
          padding: unit(16px) unit(42px);
        }
      }
    }
  }

  .customMappedRow {
    .custom-drivenrow-container {
      flex: 1;
      flex-wrap: wrap;

      .column-40 {
        @media screen and (min-width: 769px) {
          margin: 8px 0;
          width: 40%;
        }
      }
    }

    .column {
      flex-direction: column;
      width: 33.33%;

      h2,
      p {
        font-size: 0.875rem !important;
      }

      textarea {
        // width: 150px;
        // max-width: 200px;
        min-height: 65px !important;
        max-height: 200px !important;
        font-size: 0.875rem;
      }

      .driven-value {
        display: flex;
        margin: 0;
        // width: 150px;
        // max-width: 250px;

        p {
          padding: 0 8px;
          align-items: center;
          display: flex;
        }
      }

      .driven-value-40 {
        width: 100%;
      }
    }
  }

  .drivenList {
    .button-container {
      width: 200px;
    }
  }
}

.dropdown-btn-container {
  .product-search {
    position: relative;
    background-color: transparent;
    padding: 0;
    border-color: $brand-secondary;
    outline: none;
    box-shadow: none;

    .input-field {
      margin-bottom: 0;
    }

    &::after {
      position: absolute;
      right: 5px;
      top: 15px;
      border-top: .5em solid;
      border-right: 0.5em solid transparent;
      border-left: .5em solid transparent;
      border-top-color: $primary-success;
    }

    &:hover {
      border-color: $brand-secondary;
      background-color: transparent;
    }
  }
}


@media screen and (max-width:1024px) {
  .employee-details-container {

    .product-container {

      .product-content {
        display: block;

        .column-container {
          .column {

            >div {
              &:nth-child(3) {
                width: 40%;
              }
            }
          }

          .button-container {
            width: 15%;
          }
        }
      }

      .total-container {
        align-items: flex-end;
        width: 100%;
        justify-content: flex-end;

        >div {
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width:$tab-device) {
  .employee-details-container {

    float: left;
    padding-bottom: 0;

    .details-content,
    .driven-container {
      display: block !important;
      float: left;
    }



    .details-content .column,
    .driven-container .column {
      width: 50%;
      padding-right: 0;
      float: left;
      margin-top: 20px;
    }

    .driven-container .column-40 {
      width: 100%;
    }

    .driven-container .column {
      margin-top: 16px;

      h2 {
        font-size: 1rem;
      }
    }

    .product-container {
      margin-top: 2rem;
      float: left;
      width: 100%;

      .total-container {
        align-items: flex-end;
        width: 100%;
        justify-content: flex-start;
        padding: 10px;

        >div {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .total-label {
          display: flex;

          .total-value {
            margin-left: 10px;
            margin-right: 10px;
            font-size: 1.8rem;
          }
        }

        p {
          text-align: left;
        }
      }

      .product-content {
        .column-container {
          .quantity-column {
            width: 80%;
          }

          .column {
            display: block !important;
            width: 100%;

            &>div {
              width: 100% !important;
              margin-bottom: 16px;
            }

            .column-input {
              .input-label {
                width: 60%;
              }

              .input-field {
                width: 30%;
                padding: 0.5rem;
              }
            }

            &:first-child {
              margin-bottom: unit(16px);
            }

            >div {
              &:nth-child(1) {
                width: 30%;
              }

              &:nth-child(2) {
                width: 28%;
                text-align: left;
              }

              &:nth-child(3) {
                width: 30%;

                .input-field {
                  width: 70%;
                  margin: 0 unit(16px);
                  padding-right: 2px;
                }
              }
            }
          }

          .button-container {
            width: 50%;
            // flex-wrap: wrap;

            button {
              width: 100%;
            }

            .project-btn {
              margin-top: 8px;
              width: 60%;
            }
          }
        }
      }
    }

    .product-model-item {
      margin-left: 0 !important;
    }
  }

  .customMappedRow {
    flex-wrap: wrap;

    .driven-content-row {
      flex-wrap: wrap;

      .custom-drivenrow-container {
        width: 100%;

        .column {
          .driven-value {
            width: 90%;
          }

          .driven-value-40 {
            width: 100%;
          }

          textarea {
            width: 100%;
            max-width: 100%;
          }
        }
      }

      .button-container {
        width: 100%;
        justify-content: unset !important;
        margin-top: 16px;
      }
    }

    .custom-drivenrow-container {
      flex-wrap: wrap;
    }
  }

  .custom-product-container {
    flex-wrap: wrap;
  }

  .custom-product-content {
    flex-wrap: wrap;

    .content-one {
      width: 100%;
      margin-bottom: 0 !important;

      .width-50 {
        width: 48% !important;
        flex-wrap: wrap !important;

        .quality-column {
          flex-wrap: wrap;

          .input-field {
            width: 100%;
          }
        }

        .textarea-container {
          width: 100%;

          textarea {
            width: 100%;
          }
        }
      }

      .heading {
        font-weight: 500;
      }

      .product-search {
        width: 80%;
      }

      .content-one-inner-flex {
        flex-wrap: wrap;
        width: 100%;

        p {
          width: 100% !important;
          margin-bottom: 8px;
        }

        .input-field {
          width: 100% !important;
          max-width: 100% !important;
          margin: 0 !important;
          font-size: 0.875rem;
        }

        .comment-title {
          margin: 0 !important;
          width: 100% !important;
          max-width: 100% !important;
        }

        .textarea-container {
          width: 80%;
        }
      }
    }

    .content-two {
      flex-wrap: nowrap;
      margin-bottom: 1rem;
      justify-content: unset !important;

      .removeInput {
        width: auto !important;
      }
    }
  }

  .required-driven-container {
    .custom-require-content {
      align-items: left;
      flex-wrap: wrap;

      .driven-value {
        margin: 0 !important;
      }
    }
  }

  // .loader-container {
  //   width: 20%;
  //   // padding-left: 16px;
  //   margin-left: 0;
  // }

  .quality-column {
    width: 80% !important;
  }

  .button-container {
    margin-right: 0;
    padding: 0;

    .removeInput {
      margin-left: 0 !important;
      margin-right: 8px;
      left: 0;
    }
  }


}

@media screen and (max-width: 425px) {
  .driven-container .column {
    width: 100% !important;
  }

  .details-content .column,
  .driven-container .column {
    width: 100% !important;
    padding-right: 0;
    float: left;
  }
}

@media screen and (max-width: 424px) {
  .custom-drivenrow-container {
    .driven-value-40 {
      width: 80% !important;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1440px) {
  .custom-product-content {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 36px;


    .content-one {
      width: 100% !important;
      flex-wrap: wrap;

      .width-50 {
        width: 48% !important;
        flex-wrap: wrap !important;
        margin-bottom: 8px;

        .comment-title {
          margin-bottom: 8px;
        }

        .quality-column {
          flex-wrap: wrap;
          width: 80%;

          .heading {
            margin-bottom: 8px;
            font-weight: 500;
            color: $body-text;
          }

          .input-field {
            width: 100% !important;
            margin-left: 0 !important;
          }
        }

        .loader-container {
          width: 20%;
        }

        .textarea-container {
          width: 100%;

          textarea {
            width: 80% !important;
            max-width: 80% !important;
            margin: 0px !important;
          }
        }
      }
    }

    .content-two {
      justify-content: space-between !important;
      width: 20% !important;

      .removeInput {
        margin-left: 0px !important;
        margin-right: 8px !important;
      }

      .project-btn {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1440px)  {
  .custom-product-content {
    .content-one  {
      align-items: center;
    }
  }
}