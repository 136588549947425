/** @format */

@import '../../styles/variables';

.employee-img-container {
      width: unit(56px);
      height: unit(56px);
      border-radius: 50%;
      overflow: hidden;
      background: gray;
      .employee-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      
    }

    .dec-container {
      width: calc(100% - 70px);
      .employee-dec {
        margin-top: unit(8px);
          font-size: unit(14px);
          font-weight: 500;
        }

    }

.modal-employee-header  {
padding: unit(32px) unit(16px) 0 ;
  .modal-name {
    color: $user-name-color;
  }
  
  .modal-dec  {
    font-weight: 400;
  }
}


.employee-main-container {
  .btn-container {
    text-align: right;
    margin-bottom: unit(16px);

    .project-btn {
      border-radius: 7px;
      font-size: unit(17px);
    }
  }

 

  .employee-container {
    background: $brand-primary;
    padding: unit(12px) unit(16px);
    border-radius: 14px;
    position: relative;
    .admin-check-container {
      position: absolute;
      top: unit(12px);
      right: unit(16px);
      width: 30px;
      .admin-check {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    
  }
}
@media screen and (max-width:1024px) {
  .employee-main-container
  { 
  .btn-container {
    text-align: center;
  }
}
}
@media screen and (max-width:$tab-device){
  .employee-main-container
  { 
    .btn-container {
      text-align: center;
    }
  }
  .modal-employee-header {
    padding: 2rem 0 0;
}
.employee-dec-container p span {
  margin-left: 0 !important;
}
.employee-dec-container p{
  margin-bottom: 1rem;
}
}